<template>
  <div v-loading = "pageloading">
    <el-card>
      <div class = "titlefont">群发信息</div>
      <div style="margin-top:20px">
        <div class = "flexstart">
          <div class = "secFont">创建时间：</div>
          <div class = "secFont" style="max-width:600px;">{{detailsmsg.AddTime}}</div>
        </div>
        <div class = "flexstart">
          <div class = "secFont">创建来源：</div>
          <div class = "secFont" style="max-width:600px;">{{detailsmsg.QyWeixinGroupMessageFromTypeValue}}-{{detailsmsg.Name}}

          </div>
          <el-button type="text" style="padding:0px;margin:0px 0px 0px 10px;line-height:1.5" @click="todetail">详情</el-button>
        </div>
        <div class = "flexstart">
          <div class = "secFont">群发内容：</div>
          <div class = "secFont" style="max-width:600px;">{{detailsmsg.Content}}</div>
        </div>
        <div class = "flexstart" v-if="detailsmsg.QyWeixinAttachmentInfoList&&detailsmsg.QyWeixinAttachmentInfoList.length">
          <div class = "secFont">内容附件：</div>
          <div class = "secFont">
            <div class = "flexRow" v-if="detailsmsg.QyWeixinAttachmentInfoList.some((v)=>{return v.Type==1})">
              <img :src="detailsmsg.QyWeixinAttachmentInfoList.find((v)=>{return v.Type==1}).ImgUrlComplete" style="width:40px;height:40px" alt="">
              <div class = "secFont">【图片】 ：</div>
              <div>{{detailsmsg.QyWeixinAttachmentInfoList.find((v)=>{return v.Type==1}).LinkName}}</div>
            </div>
            <div class = "flexRow" v-if="detailsmsg.QyWeixinAttachmentInfoList.some((v)=>{return v.Type==2})">
              <img :src="detailsmsg.QyWeixinAttachmentInfoList.find((v)=>{return v.Type==2}).ImgUrlComplete?
              detailsmsg.QyWeixinAttachmentInfoList.find((v)=>{return v.Type==2}).ImgUrlComplete:(imgurl+'/image/defaultlink.png')"
               style="width:40px;height:40px" alt="">
              <!-- /image/defaultlink.png -->
              <div class = "secFont">【链接】 ：</div>
              <div>{{detailsmsg.QyWeixinAttachmentInfoList.find((v)=>{return v.Type==2}).LinkName}}</div>
            </div>
            <div class = "flexRow" v-if="detailsmsg.QyWeixinAttachmentInfoList.some((v)=>{return v.Type==3})">
              <img :src="detailsmsg.QyWeixinAttachmentInfoList.find((v)=>{return v.Type==3}).ImgUrlComplete" style="width:40px;height:40px" alt="">
              <div class = "secFont">【小程序】 ：</div>
              <div>{{detailsmsg.QyWeixinAttachmentInfoList.find((v)=>{return v.Type==3}).Description}}</div>
            </div>
          </div>
        </div>
        <div class = "flexstart" v-else>
          <div class = "secFont">内容附件：</div>
          <div class = "secFont">未选择附件</div>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div class = "titlefont">群发概况</div>
      <div style="margin-top:20px">
        <div class = "survey-flexRow">
          <div class = "bigbox flexCol">
            <div class = "bigbox-topfont">{{detailsmsg.EmployeeHaveSendCount}}</div>
            <div class = "bigbox-buttomfont">已发送成员</div>
          </div>
          <div class = "bigbox flexCol">
            <div class = "bigbox-topfont">{{detailsmsg.SendMemberSuccessCount}}</div>
            <div class = "bigbox-buttomfont">已送达客户</div>
          </div>
          <div class = "line"></div>
          <div class = "bigbox flexCol">
            <div class = "bigbox-topfont">{{detailsmsg.EmployeeNotSendCount}}</div>
            <div class = "bigbox-buttomfont">未发送成员</div>
          </div>
          <div class = "bigbox flexCol">
            <div class = "bigbox-topfont">{{detailsmsg.NotSendMemberCount}}</div>
            <div class = "bigbox-buttomfont">未送达客户</div>
          </div>
          <div class = "line"></div>
          <div class = "bigbox flexCol">
            <div class = "bigbox-topfont">{{detailsmsg.LimitMemberFailCount}}</div>
            <div class = "bigbox-buttomfont">客户接收达到上限</div>
          </div>
          <div class = "bigbox flexCol">
            <div class = "bigbox-topfont">{{detailsmsg.SendMemberFailCount}}</div>
            <div class = "bigbox-buttomfont">因客户不是好友发送失败</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div class = "titlefont">员工群发详情</div>
      <div style="margin-top:20px">
        <div class = "survey-flexRow">
          <div class = "survey-flexRow">
            <div class = "keyfont">员工任务状态：</div>
            <el-select v-model="result">
              <el-option v-for="v in resultlist" :key="v.value" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <el-button type="primary" style="margin-left:10px" @click="query">查询</el-button>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist" v-loading="tableloading">
            <el-table-column prop="" label="员工" width="300px">
              <template slot-scope="scope">
                <div class = "survey-flexRow">
                  <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaulthead" style="width:40px;height:40px" alt="">
                  <div style="margin-left:5px;">
                    <div class = "fonthidden" style="width:250px">{{scope.row.Name}}</div>
                    <div class = "fonthidden" style="width:250px">{{scope.row.Phone}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="StatusValue" label="任务状态">
              <template slot-scope="scope">
                <div v-if="scope.row.status==1" style="color:#f56c6c">发送失败</div>
                <div v-else>{{scope.row.StatusValue}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="发送时间">
              <template slot-scope="scope">
                <div v-if="scope.row.status!=2">未发送</div>
                <div v-else>{{scope.row.SendTime}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="SendMemberCount" label="已送达客户数"></el-table-column>
            <el-table-column prop="NotSendMemberCount" label="未送达客户数"></el-table-column>
            <el-table-column prop="LimitMemberCount" label="客户接收达到上限"></el-table-column>
            <el-table-column prop="FailMemberCount" label="因客户不是好友发送失败"></el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.status==0" @click="remindstaff(scope.row)">提醒员工</el-button>
                <el-button type="text" @click="lookdetail(scope.row)" v-else-if="scope.row.status==2">客户接收明细</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="detailshow" title="客户接收明细" custom-class="bodypad" @closed="closeddetail">
      <div class = "survey-flexRow">
          <div class = "survey-flexRow">
            <div class = "keyfont">客户接收结果：</div>
            <el-select v-model="receive">
              <el-option v-for="v in receivelist" :key="v.value" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <el-button type="primary" style="margin-left:10px" @click="query2">查询</el-button>
      </div>
      <div style="margin-top:10px">
          <el-table :data="tablelist2" v-loading="tableloading2">
            <el-table-column prop="" label="客户" width="300px">
              <template slot-scope="scope">
                <div class = "survey-flexRow">
                  <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaulthead" style="width:40px;height:40px" alt="">
                  <div style="margin-left:5px;">
                    <div class = "fonthidden" style="width:250px">{{scope.row.WxNickname}}</div>
                    <div class = "fonthidden" style="width:250px">{{scope.row.Phone}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="StatusValue" label="接收结果"></el-table-column>
            <!-- <el-table-column prop="" label="接收时间"></el-table-column> -->
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right" v-if="total2">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total2">
          </el-pagination>
        </div>
        <!-- <div style="text-align:right;margin-top:10px">
          <el-button @click="closeddetail">取消</el-button>
          <el-button type="primary" @click="closeddetail">确定</el-button>
        </div> -->
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import {
  qyweixingroupmessageqyweixingroupmessagerecorddetail,
  qyweixingroupmessageqyweixingroupmessagereceivelist,
  qyweixingroupmessageqyweixingroupmessageremindemployee
} from "@/api/sv3.0.0"
export default {
  data () {
    return {
      pageloading:false,
      detailsmsg:{},
      defaulthead:config.DEFAULT_HEADER,
      imgurl:config.IMG_BASE,
      result:null,
      resultlist:[
        {value:null,label:'全部'},
        {value:0,label:'未发送'},
        {value:2,label:'已发送'},
        {value:1,label:'发送失败'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      sizepage:20,
      total:null,


      msg:{},
      detailshow:false,
      tablelist2:[],
      tableloading2:false,
      currentPage2:1,
      sizepage2:20,
      total2:null,
      receive:null,
      receivelist:[
        {value:null,label:'全部'},
        // {value:0,label:'未送达'},
        {value:1,label:'已送达'},
        {value:2,label:'因客户不是好友导致发送失败'},
        {value:3,label:'接收达到上限'},
      ]
    }
  },
  created () {
    this.getdetailsmsg()
  },
  methods: {
    todetail(){
      this.$router.push({
        path:'/smartmarket/automarket/configuration',
        query:{
          Id:this.detailsmsg.AutoMarketingId
        }
      })
    },
    remindstaff(e){
      this.$confirm('是否确认给该成员推送提醒？系统将通过【企店助手】提醒员工，员工需要进入企业微信点击发送', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.toremindstaff(e)
      }).catch(() => {});
    },
    async toremindstaff(e){
      this.pageloading = true
      try{
        let msg = await e
        let data = {
          Id:this.$route.query.Id,
          UserId:await msg.UserId,
        }
        let result = await qyweixingroupmessageqyweixingroupmessageremindemployee(data)
        if(result.IsSuccess){
          this.$message.success('操作成功')
          this.getdetailsmsg()
        }
      }finally{
        this.pageloading = false
      }
    },
    closeddetail(){
      this.receive = null
      this.currentPage2=1
    },
    query2(){
      this.currentPage2=1
      // console.log(this.receive,'123')
      this.gettablelist2()
    },
    handleSizeChange2(e){
      this.currentPage2=1
      this.sizepage2=e
      this.gettablelist2()
    },
    handleCurrentChange2(e){
      this.currentPage2=e
      this.gettablelist2()
    },
    lookdetail(e){
      this.detailshow = true
      this.msg=e
      this.gettablelist2()
    },
    async gettablelist2(){
      this.tableloading2=true
      try{
        let data = {
          Id:this.$route.query.Id,
          UserId:this.msg.UserId,
        }
        let result = await qyweixingroupmessageqyweixingroupmessagereceivelist(data)
        if(result.IsSuccess){
          let list = result.Result.filter((v)=>{
            if(this.receive!=null){
              return v.status == this.receive
            }else{
              return v
            }
          })
          if(list&&list.length){
            this.filtertablelist2(list)
          }else{
            this.total2 = null
            this.tablelist2 = []
          }
        }
      }finally{
        this.tableloading2=false
      }
    },
    filtertablelist2(list){
      this.total2 = list.length
      let min = (this.currentPage2-1)*this.sizepage2
      let max = this.sizepage2*this.currentPage2
      this.tablelist2 = list.filter((v,i)=>{
        return i>=min && i<max
      })
    },
    query(){
      this.currentPage=1
      this.getdetailsmsg()
    },
    handleSizeChange(e){
      this.currentPage=1
      this.sizepage=e
      this.getdetailsmsg()
    },
    handleCurrentChange(e){
      this.currentPage=e
      this.getdetailsmsg()
    },
    async getdetailsmsg(){
      this.pageloading=true
      try{
        let data = {
          Id:this.$route.query.Id
        }
        let result = await qyweixingroupmessageqyweixingroupmessagerecorddetail(data)
        if(result.IsSuccess){
          this.detailsmsg = result.Result
          // console.log(this.detailsmsg)
          let list = this.detailsmsg.EmployeeList.filter((v)=>{
            if(this.result!=null){
              return v.status == this.result
            }else{
              return v
            }
          })
          this.gettablelist(list)
        }
      }finally{
        this.pageloading=false
      }
    },
    gettablelist(list){
      let min = (this.currentPage-1)*this.sizepage
      let max = this.sizepage
      this.total = list.length
      this.tablelist = list.filter((v,i)=>{
        return i>=min && i<max
      })
    },
  },
}
</script>

<style lang = "less" scoped>
  .titlefont{
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  .flexstart{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 20px 0px;
  }
  .secFont{
    font-size: 14px;
    /* white-space: nowrap; */
    color: #666666;
    line-height: 1.5;
    
  }
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
  }
  .flexRow:first-child{
    margin: 0px 0px 10px 0px;
  }
  .survey-flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .flexCol{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bigbox{
    /* border:1px solid black; */
    padding:5px 50px;
    .bigbox-topfont{
      font-size: 18px;
      /* font-weight: bold; */
      color: #303133;
      white-space: nowrap;
    }
    .bigbox-buttomfont{
      font-size: 14px;
      font-weight: 100;
      color: #606266;
      margin-top: 15px;
      white-space: nowrap;
    }
  }
  .bigbox:first-child{
    padding:5px 30px 5px 10px;
  }
  .line{
    width: 1px;
    height: 37px;
    opacity: 1;
    border: 1px solid #DCDFE6;
    margin:0px 30px
  }
  .keyfont{
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    white-space: nowrap;
  }
  .fonthidden{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:normal;
  }
</style>
<style>

  .bodypad .el-dialog__body{
    padding: 20px 20px;
  }
</style>